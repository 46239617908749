.errdis {
    color: red;
}

.cimg {
    width: 50px !important;
    height: 50px !important;
}

.audpl {
    width: 100%;
}

.vidpl {
    width: 100%;
}

.gap {
    padding-top: 5%;
}

.sidec {
    padding-top: 10%;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.bbs {
    color: white;
    text-align: center;
}

.zb {
    height: auto !important;
    padding-top: 5%;
    padding-bottom: 5%;
    background-image: url('assets/bg.webp');
}

.ht {
    color: white;
}

.cm {
    color: white !important;
}

.rs {
    padding-top: 0px !important;
}

#nprogress .bar {
    background: red !important;
}

#nprogress .peg {
    box-shadow: 0 0 10px red, 0 0 5px red !important;
}

#nprogress .spinner-icon {
    border-top-color: red !important;
    border-left-color: red !important;
}

.navbar-toggler {
    color: white;
}